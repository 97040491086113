<template>
  <card-component title="Configure Organisation" icon="fa-building">
    <form v-if="form" @submit.prevent="() => {}">
      <!-- <b-field horizontal label="Avatar">
        <file-picker />
      </b-field>
      <hr /> -->
      <b-field horizontal label="Name">
        <b-input
        v-model="form.name"
        name="name"
        :placeholder="organisation.name ? organisation.name : ''"
        required
        :disabled="!this.profile.isRootAccount"
        />
      </b-field>
      <b-field horizontal label="Max Concurrent Active Devices">
        <b-input :value="organisation.maxConcurrentUsers" name="name" disabled />
      </b-field>
      <b-field v-if="organisation.noOfUserCredits" horizontal label="User Credits" message="1 user credit is used up for every 1 new player.">
        <b-input :value="organisation.noOfUserCredits" name="name" disabled />
      </b-field>
      <b-field horizontal label="Title"  message="Organisation title to show in game lobby. Leaving blank will show PlayTours by default.">
        <b-input
        v-model="form.title"
        name="title"
        :placeholder="organisation.title ? organisation.title : ''"
        :disabled="!this.profile.isRootAccount"
        />
      </b-field>
      <b-field horizontal label="Logo">
        <div v-if="form.logo" class="container">
          <div class="box" v-bind:class="{'is-hidden': !form.logo }">
            <img class="files-image" :src="form.logo">
          </div>
        </div>
        <div class="container">
          <button
          v-if="form.logo"
          @click="form.logo = null"
          class="button is-outlined is-danger mb-2 mr-2"
          :disabled="!this.profile.isRootAccount"
          >
            Remove
          </button>
          <button
          @click="pickFile"
          class="button is-primary mb-2"
          :disabled="!this.profile.isRootAccount"
          >
            {{ form.logo ? 'Replace' : 'Upload' }}
          </button>
          <div class="container is-size-7 has-text-grey">
            Organisation logo to show in game lobby.
          </div>
          <input
          type="file"
          style="display: none"
          ref="fileInput"
          accept="image/*"
          @change="filePicked"
          >
        </div>
      </b-field>
      <b-field horizontal label="Disable Max Concurrent Email Notification" message="By default, an email notification is sent when 90% of your quota is exceeded.">
        <b-switch v-model="form.isDisabledMaxConcurrentEmail"></b-switch>
      </b-field>
      <b-field horizontal label="Bookeo API Key" message="Optional. You can integrate your Bookeo account to open up games to the public with an automated scheduling and ticketing system.">
        <b-input
        v-model="form.bookeoApiKey"
        name="bookeoApiKey"
        :disabled="!this.profile.isRootAccount"
        />
      </b-field>

    </form>
  </card-component>
</template>

<script>
import firebaseApp from '@/firebase/init'
import CardComponent from '@/components/CardComponent'
import imageCompression from 'browser-image-compression'
import { debounce } from '@/utils/debounce'

export default {
  name: 'OrganisationForm',
  components: {
    CardComponent
    // FilePicker
  },
  data () {
    return {
      allowConfigureOrganisation: false,
      errorMessage: null,
      isFileUploaded: false,
      loading: false,
      form: {
        name: null,
        email: null,
        title: null,
        logo: null,
        isDisabledMaxConcurrentEmail: false
      },
      filename: null,
      image: null
    }
  },
  computed: {
    profile () {
      return this.$store.state.profile
    },
    organisation () {
      return this.$store.state.organisation
    }
  },
  mounted () {
    this.form = { ...this.organisation }
  },
  methods: {
    pickFile () {
      this.loading = false
      // this.form.logo = null
      this.filename = null
      this.image = null
      this.$refs.fileInput.value = null
      this.$refs.fileInput.click()
    },
    async filePicked (event) {
      const files = event.target.files
      const filename = files[0].name
      this.ext = filename.slice(filename.lastIndexOf('.'))
      this.filename = filename.split('.')[0] + crypto.randomUUID()
      if (filename.lastIndexOf('.') <= 0) {
        return alert('Please add a valid file!')
      }
      const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
        this.imageUrl = fileReader.result
      })
      fileReader.readAsDataURL(files[0])
      this.image = files[0]
      this.compress()
      await firebaseApp.storage()
        .ref(`logos/${this.organisation.id}/${this.filename}`)
        .put(this.image)
      await firebaseApp.storage()
        .ref(`logos/${this.organisation.id}/${this.filename}`)
        .getDownloadURL().then(downloadURL => {
          this.form.logo = downloadURL
        })
    },
    async compress () {
      this.compressing = true
      this.image = await imageCompression(this.image, {
        maxSizeMB: 0.49
      })
      this.compressing = false
    },
    configureOrganisation () {
      this.loading = true
      this.errorMessage = null
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'configure-organisation',
        name: this.form.name,
        title: this.form.title,
        logo: this.form.logo,
        bookeoApiKey: this.form.bookeoApiKey,
        isDisabledMaxConcurrentEmail: this.form.isDisabledMaxConcurrentEmail
      }).then(result => {
        this.$buefy.toast.open({
          message: 'Changes auto-saved.',
          type: 'is-primary',
          queue: false
        })
        this.loading = false
      }).catch(err => {
        this.errorMessage = err.message
        this.loading = false
        this.$buefy.toast.open({
          message: 'Failed to auto-save. Error: ' + err.message,
          type: 'is-danger',
          queue: false
        })
      })
    },
    debounceSave: debounce(function () {
      this.configureOrganisation()
    }, 1000)
  },
  watch: {
    form: {
      handler (newValue, oldValue) {
        if (oldValue && oldValue.name) {
          this.debounceSave()
        }
      },
      deep: true
    },
    'form.name': function (newVal) {
      if (
        newVal && newVal.length > 0
      ) {
        this.allowConfigureOrganisation = true
      } else {
        this.allowConfigureOrganisation = false
      }
    }
  }
}
</script>
